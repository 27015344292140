<template>
	<start-page-layout id="landing-page">
		<template v-slot:body>
			<swiper
				:speed="swipeSpeed"
				@swiper="setSwiperRef"
				@slideChange="onSlideChange"
				class="landing-swiper-con"
			>
				<swiper-slide v-for="content in contents" :key="content.index">
					<div
						class="landing-background-img"
						:class="content.image"
					></div>
				</swiper-slide>
			</swiper>

			<div class="landing-content-wrapper">
				<div class="landing-content-con">
					<p class="heading-4 white-color title">
						<b>{{ currentContent.value.title }}</b>
					</p>
					<div class="support-con">
						<base-icon
							class="sd-icon-btn-xsm sd-icon-btn-ghost"
							iconName="help_outline"
							iconSize="lg"
							@click="goTo('FAQ')"
						></base-icon>
					</div>

					<p class="body-2-normal medium white-color">
						{{ currentContent.value.description }}
					</p>

					<div class="progress-con">
						<base-slab
							:currentIndex="currentContent.value.index"
							:totalIndex="contents.length"
							@slab-clicked="slideTo"
						></base-slab>
					</div>

					<div class="button-con-wrapper">
						<!-- <div class="support-con">
							<base-icon
								class="sd-icon-btn-xsm sd-icon-btn-ghost"
								iconName="help_outline"
								iconSize="lg"
								@click="goTo('FAQ')"
							></base-icon>
						</div> -->
						<div class="button-con">
							<base-button
								btnLabel="Previous"
								class="sd-btn-tertiery sd-btn-sm body-1-normal medium prev-btn"
								@click="slidePrev"
								v-show="currentContent.value.index != 0"
							/>

							<base-button
								:btnLabel="
									currentContent.value.index !=
									contents.length - 1
										? 'Next'
										: 'Get Started'
								"
								iconName="arrow_forward"
								:isRightIcon="true"
								class="sd-btn-primary sd-btn-md body-1-normal medium"
								@click="
									slideNext(
										currentContent.value.index,
										contents.length
									)
								"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</start-page-layout>
</template>

<script>
import StartPageLayout from "@/components/layout/StartPageLayout.vue";
import BaseSlab from "../page-survey/components/BaseSlab.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { ref, reactive, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import utility from "@/presentation/mixins/utility.js";

export default {
	name: "LandingCarouselPage",
	mixins: [utility],
	components: {
		StartPageLayout,
		Swiper,
		SwiperSlide,
		BaseSlab,
	},
	setup() {
		const router = useRouter();

		let swiperRef = null;

		const setSwiperRef = (swiper) => {
			swiperRef = swiper;
		};

		const onSlideChange = () => {
			currentContent.value = contents.value[swiperRef.activeIndex];
		};

		const slideTo = (index) => {
			swiperRef.slideTo(index);
		};

		const slidePrev = () => {
			swiperRef.slidePrev();
		};

		let toLoginPage = () => {
			router.push({
				name: "LoginPage",
			});
		};

		const slideNext = (contentIndex, contentLength) => {
			contentIndex != contentLength - 1
				? swiperRef.slideNext()
				: toLoginPage();
		};

		const swipeSpeed = ref(250);
		const contents = ref([
			{
				index: 0,
				title: "Eat Better",
				description:
					"Remove the guesswork when deciding what to eat. Learn about your gut health, mental health and more, take quizzes to assess your current health scores and get surprises in the form of healthy & tasty treats! ",
				image: "image-3",
			},
			{
				index: 1,
				title: "Perform Better",
				description:
					"Better For Me is precisely for fitness-focused, health-driven individuals like you. Learn about the foods that will fuel you to do more, to conquer them all.",
				image: "image-1",
			},
			{
				index: 2,
				title: "Live Better",
				description:
					"We know you don't need another health app. BFM is so much more than that. Let us be your companion that journeys with you to live the best and most delicious life possible.",
				image: "image-2",
			},
		]);
		const currentContent = reactive({});

		onBeforeMount(() => {
			currentContent.value = contents.value[0];
		});

		return {
			contents,
			currentContent,
			swipeSpeed,
			setSwiperRef,
			onSlideChange,
			slideTo,
			slideNext,
			slidePrev,
		};
	},
};
</script>

<style scoped lang="scss">
#landing-page {
	animation-duration: 0.5s;
	animation-name: fadein;
	animation-fill-mode: forwards;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.landing-swipper-con {
	z-index: 1;
}

.landing-background-img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;

	&.image-1 {
		background-image: url("@/assets/images/landing_1.jpg");
	}

	&.image-2 {
		background-image: url("@/assets/images/landing_2.jpg");
	}

	&.image-3 {
		background-image: url("@/assets/images/landing_3.jpg");
	}
}

.landing-content-wrapper {
	position: relative;
	z-index: 2;
}

.landing-content-con {
	text-align: left;
	padding: 24px;
	background: linear-gradient(
		180deg,
		rgba(15, 23, 42, 0) -9.29%,
		#0f172a 100%
	);
	backdrop-filter: blur(20px);
	border-radius: 12px 12px 0px 0px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	.title {
		margin-bottom: 16px;
		position: relative;
	}
}

.progress-con {
	padding: 24px 0;
}

.button-con-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.button-con {
	display: flex;
	justify-content: space-between;
	gap: 12px;

	.prev-btn {
		background-color: transparent;
		color: $white;
	}
}

.support-con {
	position: absolute;
	top: -55px;
	border-radius: 50%;
	background-color: $white;
	transition: all 0.2s ease-in-out;

	.sd-icon-btn {
		color: $primary-color-90;
	}
}

.swiper {
	height: 100%;
}
</style>
