<template>
	<start-page-layout id="welcome-page">
		<template v-slot:body>
			<div class="welcome-logo">
				<img
					width="40"
					height="48"
					:src="require('@/assets/images/anne-logo.svg')"
				/>
			</div>
			<p class="title-text heading-4">
				<span>Eat Better</span>
				<br />
				<span>Perform Better</span>
				<br />
				<span>Live Better</span>
			</p>

			<div class="image-con"></div>
			<div class="welcome-button-con">
				<base-button
					btnLabel="Learn More"
					iconName="arrow_forward"
					:isRightIcon="true"
					class="sd-btn-primary sd-btn-md body-1-normal medium"
					@click="skipWelcome"
				/>
				<base-button
					btnLabel="Sign In / Sign Up"
					class="sd-btn-secondary sd-btn-md body-1-normal medium"
					@click="goTo('LoginPage')"
				/>
			</div>
		</template>
	</start-page-layout>
</template>

<script>
import StartPageLayout from "@/components/layout/StartPageLayout.vue";
import utility from "@/presentation/mixins/utility.js";

export default {
	name: "WelcomePage",
	components: {
		StartPageLayout,
	},
	mixins: [utility],
	data() {
		return {};
	},
	methods: {
		skipWelcome() {
			this.$router.push({
				name: "LandingCarousel",
			});
		},
	},
};
</script>

<style scoped lang="scss">
#welcome-page {
	height: 100%;

	&.fade-out {
		animation-duration: 1s;
		animation-name: fadeout;
		animation-fill-mode: forwards;
	}
}

.welcome-logo {
	width: 40px;
	height: 48px;
	margin: 32px auto 0;
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.title-text {
	margin: 22px auto 16px;
}

.sub-title-text {
	padding: 0 24px;
}

.image-con {
	background-image: url("@/assets/images/bfm.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 70%;
	width: 100%;
}

.welcome-button-con {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: flex-end;
	gap: 20px;
}

@media (min-width: 672px) {
	.image-con {
		background-size: contain;
	}
}
</style>
